import {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useQueryClient} from 'react-query'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {EpisodeDTO} from '../../../../models/dbModels'
import {ReorderEpisodeRequest} from '../../core/_request'
import {Button} from 'react-bootstrap'

type Props = {
  data: EpisodeDTO
  index: number
  lastIndex: number
}

export const ReorderCell: FC<Props> = ({data, index, lastIndex}) => {
  const {query: _query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  const reOrder = async (moveUp: boolean) => {
    const res: any = await ReorderEpisodeRequest(data.episodeId, moveUp)
    console.log(res)

    queryClient.invalidateQueries()
    refetch()
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row', gap: 4}}>
      <div>
        <Button disabled={index === 0} style={{padding: 5}} onClick={() => reOrder(true)}>
          <KTSVG path='/media/icons/duotune/arrows/arr003.svg' className='svg-icon-1 m-0' />
        </Button>
      </div>
      <div>
        <Button disabled={index === lastIndex} onClick={() => reOrder(false)} style={{padding: 5}}>
          <KTSVG path='/media/icons/duotune/arrows/arr004.svg' className='svg-icon-1 m-0' />
        </Button>
      </div>
    </div>
  )
}
