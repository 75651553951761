import clsx from 'clsx'
import './index.css'
import {Field as FormikField} from 'formik'
import {DatePicker} from 'antd'
import moment from 'moment'
import CustomEditor from '../common/customEditor'

export const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  valueProcessor,
  fake,
  isFileUpload,
  children,
  select,
  datepicker,
  textarea,
  wysiwyg,
  ...props
}: any) => {
  const {touched, errors, setFieldValue} = form
  const showError = fake ? false : touched[field.name] && errors[field.name]
  return (
    <div>
      <div className='upload-overlay-parent'>
        {select && (
          <select
            className={clsx('form-control form-control-solid', {'is-invalid': showError})}
            {...props}
            {...field}
            onChange={(e) => {
              console.log(e.target.value)
              form.handleChange(e)
            }}
          >
            {children}
          </select>
        )}

        {datepicker && (
          <DatePicker
            name={field.name}
            format={'YYYY-MM-DD'}
            defaultOpen={false}
            value={field.value ? moment(field.value, 'YYYY-MM-DD') : null}
            onChange={(date, dateString) => {
              form.handleChange({
                target: {
                  name: field.name,
                  value: dateString,
                },
              })
            }}
            className={clsx([
              {'is-invalid': form.errors[field.name]},
              {'is-valid': !form.errors[field.name]},
              'form-control form-control-solid mb-3 mb-lg-0',
            ])}
          />
        )}

        {!select && !datepicker && !textarea && !wysiwyg && (
          <input
            type='text'
            className={clsx('form-control form-control-solid', {'is-invalid': showError})}
            {...props}
            {...field}
            value={!valueProcessor ? field.value : valueProcessor(field.value)}
          />
        )}

        {textarea && (
          <textarea
            className={clsx('form-control form-control-solid', {'is-invalid': showError})}
            {...props}
            {...field}
            value={!valueProcessor ? field.value : valueProcessor(field.value)}
            style={{resize: 'none'}}
            rows={7}
          />
        )}

        {wysiwyg && (
          <CustomEditor value={!valueProcessor ? field.value : valueProcessor(field.value)} onChange={(value: any) => setFieldValue(field.name, value)} />
        )}

        {isFileUpload && (
          <div className='upload-overlay'>
            <input
              type='file'
              id={isFileUpload}
              onChange={(e: any) => {
                setFieldValue(field.name, e.target.files[0].name)
              }}
            />
          </div>
        )}
      </div>
      {showError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{errors[field.name]}</div>
        </div>
      )}
    </div>
  )
}

export const Field = (props: any) => (
  <div>
    <label className={clsx('fs-6 mb-2 mt-8', {required: props.required})}>{props.label}</label>
    {props.fake && <CustomInputComponent {...props} form={{}} field={{value: props.value}} />}
    {!props.fake && <FormikField component={CustomInputComponent} {...props} />}
  </div>
)
