import {FC} from 'react'
import {EpisodeDTO} from '../../../../models/dbModels'

type Props = {
  episode: EpisodeDTO
}

const CustomCheckboxCell: FC<Props> = ({episode}) => {
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input type='checkbox' checked={episode.isQr} className='form-check-input' readOnly />
    </div>
  )
}

export {CustomCheckboxCell}
