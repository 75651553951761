import { KTSVG } from '../../../../_metronic/helpers';

type Props = {
  onClose?: (event: any) => void;
};

const CopyEpisodeModalHeader = ({onClose}: Props) => {
  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{'Epizód másolása'}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => onClose?.(undefined)}
        style={{ cursor: 'pointer' }}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  );
};

export { CopyEpisodeModalHeader };
