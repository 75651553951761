import {Column} from 'react-table'
import {UserAccessCell} from './UserAccessCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionCell} from './UserSelectionCell'
import {UserStatusCell} from './UserStatusCell'
import {UserDTO} from '../../../../../models/dbModels'

const usersColumns = (
  selectedUsers: number[],
  onUserSelect: (userId: number) => void
): Column<UserDTO>[] => [
  {
    Header: 'Kiválasztás',
    id: 'selection',
    Cell: ({row}: {row: {original: UserDTO}}) => (
      <UserSelectionCell
        user={row.original}
        selectedUsers={selectedUsers}
        onUserSelect={onUserSelect}
      />
    ),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Név' className='min-w-125px' />,
    accessor: 'firstName',
    Cell: ({row}: {row: {original: UserDTO}}) => (
      <span>
        {row.original.firstName} {row.original.lastName}
      </span>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='E-mail' className='min-w-125px' />
    ),
    accessor: 'emailAddress',
    Cell: ({row}: {row: {original: UserDTO}}) => <span>{row.original.emailAddress}</span>,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Szerepkör' className='min-w-125px' />
    ),
    accessor: 'roleId',
    Cell: ({row}: {row: {original: UserDTO}}) => <UserAccessCell user={row.original} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Státusz' className='min-w-125px' />
    ),
    id: 'isDeleted',
    Cell: ({row}: {row: {original: UserDTO}}) => <UserStatusCell user={row.original} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Tevékenység' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({row}: {row: {original: UserDTO}}) => <UserActionsCell user={row.original} />,
  },
]

export {usersColumns}
