/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import Swal from 'sweetalert2'
import {useMutation, useQueryClient} from 'react-query'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {EpisodeDTO} from '../../../../models/dbModels'
import {DeleteEpisodeRequest, DuplicateEpisodeRequest} from '../../core/_request'
import {useNavigate} from 'react-router-dom'
import { CopyEpisodeModal } from '../../copy-modal/CopyEpisodeModal'

type Props = {
  data: EpisodeDTO
}

const ActionsCell: FC<Props> = ({data}) => {
  const {setItemForUpdate} = useListView()
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditPage = () => {
    setItemForUpdate?.(data)
    navigate(`/series/${data.seriesId}/episode/edit/${data.episodeId}`)
  }

  const deleteItem = useMutation(async () => await DeleteEpisodeRequest(data.episodeId), {
    onSuccess: () => {
      // TODO investigate why episode list doesn't get updated after item delete
      // queryClient.invalidateQueries([`${QUERIES.EPISODES}-${query}`]);
      queryClient.invalidateQueries()
      refetch()
    },
  })

  const duplicateItem = useMutation(async () => await DuplicateEpisodeRequest(data.episodeId), {
    onSuccess: () => {
      queryClient.invalidateQueries()
      refetch()
    },
  })

  const handleDeleteClick = async () => {
    const result = await Swal.fire({
      title: 'Biztos, hogy törölni szeretnéd az epizódot?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Törlés',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      await deleteItem.mutateAsync()
    }
  }

  const duplicateEpisode = async () => {
    const res = await duplicateItem.mutateAsync()
    console.log('******', res)
  }

  const copyEpisode = async () => {
    console.log('copy episode')
    setIsCopyModalOpen(true)
    // Open modal
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px p-3'
        data-kt-menu='true'
      >
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={duplicateEpisode}>
            Duplikálás
          </a>
        </div>
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={copyEpisode}>
            Másolás
          </a>
        </div>
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={openEditPage}>
            Szerkesztés
          </a>
        </div>
        <div className='menu-item'>
          <a className='menu-link color-esl-red px-3' onClick={handleDeleteClick}>
            Töröl
          </a>
        </div>
      </div>
      {isCopyModalOpen && <CopyEpisodeModal episodeId={data.episodeId} isLoading={false} onClose={() => setIsCopyModalOpen(false)} />}
    </>
  )
}

export {ActionsCell}
