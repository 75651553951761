import { KTSVG } from '../../../../_metronic/helpers';
import { useListView } from '../core/ListViewProvider';

const EditOrCreateEpisodeModalHeader = () => {
  const { setItemForUpdate, itemForUpdate } = useListView();

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{itemForUpdate?.episodeId ? 'Epizód szerkesztése' : 'Epizód létrehozása'}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemForUpdate?.(undefined)}
        style={{ cursor: 'pointer' }}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  );
};

export { EditOrCreateEpisodeModalHeader };
