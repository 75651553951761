import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { isNotEmpty } from '../../../../_metronic/helpers';
import { useListView } from '../core/ListViewProvider';
import { GetSeriesByCondition } from '../core/_requests';
import { CreateSeriesModalForm } from './content/CreateSeriesModalForm';
import { EditSeriesModalForm } from './content/EditSeriesModalForm';
import { GetSeriesByConditionRequest, SeriesDTO } from '../../../models/dbModels';

type Props = {
  seriesId?: number;
};

const EditOrCreateSeriesModalFormWrapper: FC<Props> = ({ seriesId }) => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const [initialLoad, setInitialLoad] = useState(true);
  const navigate = useNavigate();

  const idToFetch = seriesId || itemForUpdate?.seriesId;
  const enabledQuery: boolean = isNotEmpty(idToFetch) && idToFetch !== 0;

  const {
    isLoading,
    data: _data,
    error,
  } = useQuery(
    `series-${idToFetch}`,
    () => {
      const request: GetSeriesByConditionRequest = { 
        seriesId: idToFetch,
        needEpisodes: true,
        needEvents: true,
        needUserXSeries: true,
       };
      return GetSeriesByCondition(request).then((response) => response?.[0]);
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err) => {
        setItemForUpdate?.(undefined);
        console.error(err);
      },
      onSuccess: (data) => {
        if (initialLoad && data) {
          setItemForUpdate?.(data);
          setInitialLoad(false);
        }
      },
    }
  );

  return (_data?.seriesId ?? 0) > 0 ? 
    <EditSeriesModalForm isLoading={isLoading} data={_data} /> :
    <CreateSeriesModalForm isLoading={isLoading} data={_data}/>;
};

export { EditOrCreateSeriesModalFormWrapper };
