import {FC, useEffect} from 'react'
import { CopyEpisodeModalHeader } from './CopyEpisodeModalHeader';
import { CopyEpisodeModalForm } from './content/CopyEpisodeModalForm';

type Props = {
  episodeId: number;
  isLoading: boolean;
  onClose: (event: any) => void;
};

const CopyEpisodeModal: FC<Props> = ({ episodeId, isLoading, onClose }) => {
  useEffect(() => {
    console.log("CopyEpisodeModal - useEffect add('modal-open')")
    document.body.classList.add('modal-open')
    return () => {
      console.log("CopyEpisodeModal - useEffect remove('modal-open')")
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_series'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <CopyEpisodeModalHeader onClose={onClose} />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-7'>
            <CopyEpisodeModalForm episodeId={episodeId} isLoading={isLoading} onClose={onClose}/>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {CopyEpisodeModal}
