import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { useAuth } from '../modules/auth';
import { RightEnum } from '../models/user';
import { SeriesPageWrapper } from '../modules/series';
import { EditOrCreateSeriesModalFormWrapper } from '../modules/series/edit-or-create-modal/EditOrCreateSeriesModalFormWrapper';
import EditEpisodePage from '../modules/episode/edit-or-create-modal/content/EditEpisodePage';

const PrivateRoutes = () => {
  const { currentUserCan } = useAuth();
  const UsersPage = lazy(() => import('../modules/users/UsersPage'));

  const EditSeriesPage = () => {
    const { seriesId } = useParams<{ seriesId: string }>();
    console.log("PrivateRoutes", seriesId);
    return <EditOrCreateSeriesModalFormWrapper seriesId={Number(seriesId)} />;
  };

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {!currentUserCan(RightEnum._admin) ? (
          <Route path='auth/*' element={<Navigate to='/series' />} />
        ) : (
          <Route path='auth/*' element={<Navigate to='/users' />} />
        )}
        {/* Pages */}
        {currentUserCan(RightEnum._admin) && (
          <Route
            path='users/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
        )}
        <Route path="/series" element={<SeriesPageWrapper />} />
        <Route path="/series/edit/:seriesId" element={<EditSeriesPage />} />
        <Route path="/series/:seriesId/episode/edit/:episodeId" element={<EditEpisodePage />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
