import {DropdownMenu} from '@/app/components/DropdownMenu'
import {Field} from '@/app/components/CustomInput'
import {dropdownMenuConfig} from '@/app/components/config'
import {Modal} from '@/app/components/Modal'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {createOrUpdateArGame, deleteArById, updateArGameFile} from './api'

const schema = Yup.object().shape({
  modelName: Yup.string().required('A mező kötelező.'),
  numberOfItems: Yup.number().required('A mező kötelező.'),
  placementRadius: Yup.number().required('A mező kötelező.'),
})

export const Ar = ({episodeId, eventId, ar, modal, setModal, refetch}: any) => {
  const isCreate = modal === 'createAr'
  const isUpdate = modal === 'updateAr'
  const isDelete = modal === 'deleteAr'
  const formNeeded = isCreate || isUpdate

  const onFormSubmit = async (values: any) => {
    const arGame = {...values}
    arGame.eventId = eventId
    arGame.numberOfItems = parseInt(arGame.numberOfItems)
    arGame.placementRadius = parseInt(arGame.placementRadius)
    arGame.arGameId = ar?.arGameId || null

    const res: any = await createOrUpdateArGame({arGame} as any)

    if (res.message) {
      alert(res.message)
      return
    }
    if (!res?.arGameId) {
      alert('Hiányzó arGameId')
      return
    }

    const formData = new FormData()
    const fileInput: HTMLInputElement = document.getElementById(
      'arImageSourceFile'
    ) as HTMLInputElement
    if (fileInput && fileInput.files && res) {
      const file = fileInput.files[0]
      formData.append('file', file)
      const updateRes = await updateArGameFile({arGameId: res?.arGameId, form: formData} as any)
      if (updateRes.message) {
        alert(updateRes.message)
        return
      } else {
        await new Promise((res) => setTimeout(res, 250))
        refetch()
        setModal('')
        return
      }
    }

    alert(res.message)
  }

  const onDeleteClick = async () => {
    const result = await deleteArById({arGameId: ar.arGameId})
    if (!result || !result.message) {
      await new Promise((res) => setTimeout(res, 250))
      refetch()
      setModal('')
      return
    }
    alert(result?.message)
  }
  return (
    <>
      {ar ? (
        <div className='mt-20'>
          <div className='d-flex justify-content-between'>
            <h2>AR</h2>
            <DropdownMenu>
              {{
                ...dropdownMenuConfig,
                edit: {
                  ...dropdownMenuConfig.edit,
                  onClick() {
                    setModal('updateAr')
                  },
                },
                remove: {
                  ...dropdownMenuConfig.remove,
                  onClick() {
                    setModal('deleteAr')
                  },
                },
              }}
            </DropdownMenu>
          </div>
          <div>
            Modellek száma: <b>{ar?.numberOfItems}</b>
          </div>
          <div>
            Elhelyezési sugár: <b>{ar?.placementRadius}</b>
          </div>
          <Field name='eventName' disabled value={ar?.modelName} fake />
        </div>
      ) : null}
      <Modal
        open={isCreate || isDelete || isUpdate}
        setModal={setModal}
        title={isUpdate ? 'AR módosítása' : isDelete ? 'AR törlése' : 'Új AR'}
      >
        {formNeeded && (
          <Formik
            initialValues={{
              modelName: ar?.modelName || '',
              numberOfItems: ar?.numberOfItems || '',
              placementRadius: ar?.placementRadius || '',
            }}
            validationSchema={schema}
            onSubmit={onFormSubmit}
            validator={() => ({})}
          >
            <Form>
              <Field name='modelName' label={'Modell'} required isFileUpload='arImageSourceFile' />
              <Field name='numberOfItems' label={'Modellek száma'} required />
              <Field name='placementRadius' label={'Elhelyezési sugár'} required />

              <div className='d-flex justify-content-center align-items-center mt-10'>
                <button type='submit' className='btn btn-primary'>
                  {isCreate ? 'HOZZÁADÁS' : 'MÓDOSÍTÁS'}
                </button>
              </div>
            </Form>
          </Formik>
        )}
        {isDelete && (
          <>
            <h3 className='text-center'>Biztosan törlöd?</h3>
            <div className='d-flex justify-content-center align-items-center mt-10'>
              <button onClick={() => setModal('')} className='btn btn-light me-4'>
                NEM
              </button>
              <button onClick={onDeleteClick} className='btn btn-danger'>
                IGEN
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
