import {FC} from 'react'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {UsersTable} from './table/UsersTable'
import {UserViewDrawer} from './user-view/UserViewDrawer'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {KTCard} from '../../../../_metronic/helpers'
import {UserEditOrCreateModal} from './user-edit-or-create-modal/UserEditOrCreateModal'

type UsersListFieldProps = {
  selectedUsers: number[]
  onUserSelect: (userId: number) => void
  loading?: boolean
}

const UsersListField: FC<UsersListFieldProps> = ({selectedUsers, onUserSelect, loading}) => {
  const {itemForUpdate} = useListView()

  return (
    <>
      <h1 className='h1 page-heading mt-10'>Társított felhasználók</h1>
      <KTCard>
        <UsersTable
          selectedUsers={selectedUsers}
          onUserSelect={onUserSelect}
          hiddenColumns={['isDeleted', 'roleId', 'actions']}
          loading={loading}
        />
        {itemForUpdate !== undefined && <UserEditOrCreateModal />}
        <UserViewDrawer />
      </KTCard>
    </>
  )
}

const UsersListFieldWrapper = ({selectedUsers, onUserSelect, loading}: UsersListFieldProps) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersListField
          selectedUsers={selectedUsers}
          onUserSelect={onUserSelect}
          loading={loading}
        />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersListFieldWrapper}
