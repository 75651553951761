import {FC, useEffect} from 'react'
import {EditOrCreateEpisodeModalHeader} from './EditOrCreateEpisodeModalHeader'
import {EditOrCreateEpisodeModalFormWrapper} from './EditOrCreateEpisodeModalFormWrapper'


type Props = {
  seriesId: number;
  isLoading: boolean;
};

const EditOrCreateEpisodeModal: FC<Props> = ({ seriesId, isLoading }) => {
  useEffect(() => {
    console.log("EditOrCreateEpisodeModal - useEffect add('modal-open')")
    document.body.classList.add('modal-open')
    return () => {
      console.log("EditOrCreateEpisodeModal - useEffect remove('modal-open')")
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_series'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <EditOrCreateEpisodeModalHeader />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-7'>
              <EditOrCreateEpisodeModalFormWrapper seriesId={seriesId} isLoading={isLoading}/>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {EditOrCreateEpisodeModal}
