import { 
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnBulletList,
  BtnNumberedList,
  BtnLink,
  createButton,
  Editor,
  EditorProvider,
  Toolbar
} from 'react-simple-wysiwyg';

const BtnAlignCenter = createButton('Align center', '≡', 'justifyCenter');
const BtnAlignRight = createButton('Align right', '⇥', 'justifyRight');
const BtnAlignLeft = createButton('Align left', '⇤', 'justifyLeft');

export default function CustomEditor({value, onChange}: any) {

  const newLineToBrValue = value?.replaceAll('\\n', '<br />').replaceAll('\n', '<br />');

  return (
    <EditorProvider>
      <Editor value={newLineToBrValue} onChange={(event: any) => onChange(event.target.value)}>
        <Toolbar>
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <BtnAlignLeft />
          <BtnAlignCenter />
          <BtnAlignRight />
          <BtnBulletList />
          <BtnNumberedList />
          <BtnLink />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
}