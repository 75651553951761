import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {Option} from '@/app/modules/widgets/components/FormikSelect'
import {useDCTables} from '../../../../providers/DCTablesProvider'

const EpisodeListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [seriesId, setSeriesId] = useState<string | undefined>()

  const {dcTables} = useDCTables()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    updateState({
      ...initialQueryState,
      filter: {seriesId},
    })
  }

  return (
    <>
      {/* <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Szűrés
      </button> */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Szűrési Opciók</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-episode-table-filter='form'>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Sorozat:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Kérjük válasszon'
              data-allow-clear='true'
              data-kt-episode-table-filter='seriesId'
              data-hide-search='true'
              onChange={(e) => setSeriesId(e.target.value)}
              value={seriesId}
            >
              <option value=''></option>
              {/* Assuming dcTables?.dC_SeriesCategory is the series list */}
              {dcTables?.dC_SeriesCategory?.map((item: Option) => (
                <option key={item.id} value={item.id as number}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-episode-table-filter='reset'
            >
              Vissza
            </button>
            {/* <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-episode-table-filter='filter'
            >
              Szűrés
            </button> */}
          </div>
        </div>
      </div>
    </>
  )
}

export {EpisodeListFilter}
