import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {EpisodeDTO, EpisodeStateEnum} from '../../../../models/dbModels'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {AxiosError, AxiosResponse} from 'axios'
import {ErrorData, errorHandler} from '../../../../../_metronic/helpers'
import {ListLoading} from '../../../../common/table/components/loading/ListLoading'
import clsx from 'clsx'
import {CreateOrUpdateEpisode} from '../../core/_request'

type Props = {
  isLoading: boolean
  seriesId: number
  data?: EpisodeDTO
}

const CreateEpisodeModalForm: FC<Props> = ({seriesId, isLoading, data}) => {
  const [success, setSuccess] = useState<boolean>(false)
  const {setItemForUpdate} = useListView()
  const {refetch, query} = useQueryResponse()

  useEffect(() => {
    if (data) {
      formik.setValues(data)
    }
  }, [data])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemForUpdate?.(undefined)
  }

  const DataForCreate: EpisodeDTO = data ?? {
    episodeId: 0,
    episodeName: '',
    seriesId: seriesId,
    isQr: false,
    episodeRewards: [],
    preconditions: [],
    state: EpisodeStateEnum.NotStarted, // Assuming a default state value
    eventId: undefined,
    extraEventId: undefined,
  }

  const formik = useFormik({
    initialValues: DataForCreate,
    validationSchema: Yup.object().shape({
      episodeName: Yup.string().min(3, 'Minimum 3 characters').required('Kötelező mező'),
    }),
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const formData = Object.assign(DataForCreate, formik.values)

        await CreateOrUpdateEpisode(formData)
        refetch()

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          cancel(true)
          resetForm()
        }, 2000)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Failed to save, please try again!')
        if (err.response) {
          const errorText = errorHandler(
            err.response.status,
            err.response.data.errorResponse?.value?.errorInfo || '',
            err.response.data.errorResponse?.value?.errorDetails || ''
          )
          setStatus(errorText)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div>
      <form
        id='kt_modal_add_create_episode_form'
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit(e)
        }}
        noValidate
      >
        <div className='d-flex flex-column me-n7 pe-7'>
          <div className='fv-row mb-8'>
            <div className='row g-4 align-items-center'>
              <div className='col-lg-6'>
                <label className='fs-6 mb-2 required'>Név</label>
                <input
                  placeholder='Epizód neve'
                  {...formik.getFieldProps('episodeName')}
                  type='text'
                  name='episodeName'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.episodeName && formik.errors.episodeName},
                    {'is-valid': formik.touched.episodeName && !formik.errors.episodeName}
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.episodeName && formik.errors.episodeName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.episodeName}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 text-end'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    placeholder='Korhatár megerősítve'
                    type='checkbox'
                    autoComplete='off'
                    checked={formik.values.isQr}
                    className='form-check-input py-5 custom-switch'
                    onChange={() => {
                      formik.setFieldValue('isQr', !formik.values.isQr)
                    }}
                  />
                  <label className='form-check-label' htmlFor='flexSwitchDefault'>
                    QR-el indítható
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {formik.status && (
          <div className='alert alert-danger mt-6'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {success && (
          <div className='mb-10 bg-light-success p-4 rounded mt-6'>
            <div className='text-success'>Sikeres mentés!</div>
          </div>
        )}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Vissza
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.touched}
          >
            <span className='indicator-label'>Mentés</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Kérem várjon...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {(formik.isSubmitting || isLoading) && <ListLoading />}
      </form>
    </div>
  )
}

export {CreateEpisodeModalForm}
