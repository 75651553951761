import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import * as authHelper from './AuthHelpers'
import { WithChildren } from '../../../../_metronic/helpers'
import { RightEnum, UserAuthModel } from '@/app/models/user'

type Trainee = {
  studentId: number
  studentName: string
  accessToken: string
  responseGroupId: number
}

type AuthContextProps = {
  auth: UserAuthModel | undefined
  saveAuth: (auth: UserAuthModel | undefined) => void
  currentUser: UserAuthModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserAuthModel | undefined>>
  logout: () => void
  currentUserCan: (permission: number) => boolean
  traineeAuth: Trainee | undefined
  trainee: Trainee | undefined
  setTrainee: Dispatch<SetStateAction<any | undefined>>
  userRights?: RightEnum[]
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  currentUserCan: () => false,
  traineeAuth: authHelper.getTraineeAuth(),
  trainee: undefined,
  setTrainee: () => { },
  userRights: []
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const[auth, setAuth] = useState<UserAuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserAuthModel | undefined>()

  const [traineeAuth, setTraineeAuth] = useState<Trainee | undefined>(authHelper.getTraineeAuth()) // TODO: type
  const [trainee, setTrainee] = useState<Trainee | undefined>(authHelper.getTraineeAuth()) // TODO: type

  const saveAuth = (auth: UserAuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }


  const currentUserCan = (permission: number) => {
    return true // TODO remove | currentUser?.role?.roleXRight?.some((right) => right.rightId === permission) || false
  }

  const userRights: RightEnum[] = []// TODO remove | currentUser?.role?.roleXRight?.map((right) => right.rightId as RightEnum) || []

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout, currentUserCan, trainee, setTrainee, traineeAuth, userRights }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, setCurrentUser, logout } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (user: UserAuthModel) => {
      try {
        if (!didRequest.current) {
          setCurrentUser(user)
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth?._temporaryPasswordTokens) {
      requestUser(auth)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])



  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
