import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useListView } from '../core/ListViewProvider';
import { CreateEpisodeModalForm } from './content/CreateEpisodeModalForm';

type Props = {
  seriesId: number;
  isLoading: boolean;
};

const EditOrCreateEpisodeModalFormWrapper: FC<Props> = ({ seriesId, isLoading }) => {
  const { itemForUpdate} = useListView();
  const navigate = useNavigate();

  if (itemForUpdate?.episodeId) {
    navigate(`/episodes/edit/${itemForUpdate.episodeId}`);
  } else if(seriesId){
    return <CreateEpisodeModalForm seriesId={seriesId} isLoading={isLoading}/>
  }

  return null;
};

export { EditOrCreateEpisodeModalFormWrapper };
