import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {AxiosError} from 'axios'
import {ErrorData, errorHandler, ID, QUERIES} from '../../../../../_metronic/helpers'
import {ListLoading} from '../../../../common/table/components/loading/ListLoading'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import FormikSelect from '@/app/modules/widgets/components/FormikSelect'
import { GetSeriesByCondition } from '@/app/modules/series/core/_requests'
import { DuplicateEpisodeRequest } from '../../core/_request'

type Props = {
  isLoading: boolean
  episodeId: number
  onClose: (event: any) => void
}

const CopyEpisodeModalForm: FC<Props> = ({episodeId, isLoading, onClose}) => {
  const [success, setSuccess] = useState<boolean>(false)
  const {refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  const { data: seriesList } = useQuery(
    `${QUERIES.SERIES}-get-all`,
    async () => {
      const series = await GetSeriesByCondition({
        page: 1,
        perPage: 999,
      });
      return series ?? [];
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const duplicateItem = useMutation(
    async (seriesId: ID) => await DuplicateEpisodeRequest(episodeId, seriesId),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries()
          refetch()
        }, 2000)
      },
    }
  )

  useEffect(() => {
    if (episodeId) {
      formik.setValues({
        episodeId,
        seriesId: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeId])

  const formik = useFormik({
    initialValues: {
      episodeId,
      seriesId: '',
    },
    validationSchema: Yup.object().shape({
      seriesId: Yup.string().required('Kérlek válassz sorozatot!'),
    }),
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const formData = formik.values

        await duplicateItem.mutateAsync(Number(formData.seriesId))

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          resetForm()
        }, 2000)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Failed to save, please try again!')
        if (err.response) {
          const errorText = errorHandler(
            err.response.status,
            err.response.data.errorResponse?.value?.errorInfo || '',
            err.response.data.errorResponse?.value?.errorDetails || ''
          )
          setStatus(errorText)
          setTimeout(() => {
            setStatus('')
          }, 2500)
        }
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='text-start'>
      <form
        id='kt_modal_add_create_episode_form'
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit(e)
        }}
        noValidate
      >
        <div className='d-flex flex-column me-n7 pe-7'>
          <div className='mb-8'>
            <FormikSelect
              labelText='Sorozat'
              selectProps={{
                ...formik.getFieldProps('seriesId'),
                onBlur: formik.handleBlur,
              }}
              options={(seriesList ?? []).map((item) => ({
                id: item.seriesId?.toString() || '',
                name: item.seriesName || '',
              }))}
              formikTouched={formik.touched.seriesId}
              formikErrors={formik.errors.seriesId}
              required={true}
            />
          </div>
        </div>
        {formik.status && (
          <div className='alert alert-danger mt-6'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {success && (
          <div className='mb-10 bg-light-success p-4 rounded mt-6'>
            <div className='text-success'>Sikeres mentés!</div>
          </div>
        )}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={onClose}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Vissza
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.touched}
          >
            <span className='indicator-label'>Mentés</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Kérem várjon...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {(formik.isSubmitting || isLoading) && <ListLoading />}
      </form>
    </div>
  )
}

export {CopyEpisodeModalForm}
