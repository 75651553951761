import {useEffect, useState} from 'react'

export const useModal = () => {
  const [modal, setModal] = useState<string | undefined>()
  useEffect(() => {
    if (modal) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [modal])
  return {modal, setModal}
}
