import {Field} from '@/app/components/CustomInput'
import {DropdownMenu} from '@/app/components/DropdownMenu'
import {Modal} from '@/app/components/Modal'
import {dropdownMenuConfig} from '@/app/components/config'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {createOrUpdateEvent, updateEventImage} from './api'
// import {deleteEventById} from './api'

const eventSchema = Yup.object().shape({
  name: Yup.string().required('A mező kötelező.'),
  description: Yup.string().min(3, 'Minimum 3 karakter.').max(10000, 'Max. 10000 karakter'),
  imageSource: Yup.string().required('A mező kötelező.'),
})

export const Event = ({episodeId, modal, setModal, event, refetch}: any) => {
  const onFormSubmit = async (values: any) => {
    const data = {
      eventId: event.id,
      episodeId: episodeId.id,
      name: values.name,
      description: values.description,
      imageSource: values.imageSource,
    }

    const res = await createOrUpdateEvent(data)
    if (res.message) {
      alert(res.message)
      return
    }

    // Create a new FormData object
    const formData = new FormData()
    // Append the file to the FormData object
    const imageInput = document.getElementById('imageSourceFile') as HTMLInputElement
    const file: any = (imageInput?.files || [])[0]
    if (file) {
      formData.append('file', file)
      const fileUploadRes = await updateEventImage({form: formData, eventId: event.id})
      if (fileUploadRes.message) {
        alert(fileUploadRes.message)
        return
      }
    }

    await new Promise((res) => setTimeout(res, 250))
    refetch()
    setModal('')
  }
  return (
    <>
      {event ? (
        <div>
          <div className='d-flex justify-content-between'>
            <h2>{event.name}</h2>
            <DropdownMenu>
              {{
                edit: {
                  ...dropdownMenuConfig.edit,
                  onClick() {
                    setModal('createOrUpdateEvent')
                  },
                },
              }}
            </DropdownMenu>
          </div>
          <p dangerouslySetInnerHTML={{__html: event.description}}></p>
          <Field name='eventName' disabled value={event.imageSource} fake />
        </div>
      ) : null}
      <Modal
        open={['createOrUpdateEvent', 'deleteEvent'].includes(modal)}
        setModal={setModal}
        title={'Új esemény'}
      >
        {'createOrUpdateEvent' === modal && (
          <Formik
            initialValues={{
              name: event?.name || '',
              description: event?.description || '',
              imageSource: event?.imageSource || '',
            }}
            validationSchema={eventSchema}
            onSubmit={onFormSubmit}
          >
            <Form>
              <Field name='name' label={'Esemény neve'} required />
              <Field name='description' label={'Esemény leírása'} required wysiwyg />
              <Field name='imageSource' label={'Kép url'} required isFileUpload='imageSourceFile' />

              <div className='d-flex justify-content-center align-items-center mt-10'>
                <button type='submit' className='btn btn-primary'>
                  MÓDOSÍTÁS
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </Modal>
    </>
  )
}
