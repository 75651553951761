/* eslint-disable react/jsx-no-target-blank */
import {useAuth} from '../../../../app/modules/auth'
import {errorHandler, isNotEmpty, KTSVG, selectUserRole} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {getUserById} from '../../../../app/modules/users/users-list/core/_requests'
import {UserDTO} from '../../../../app/models/dbModels'

const SidebarFooter = () => {
  const {currentUser, logout} = useAuth()
  const enabledQuery: boolean = isNotEmpty(currentUser)

  const {
    isLoading,
    data: user,
    error,
    refetch: refetchUser,
  } = useQuery(
    `user-${currentUser?.userId}`,
    () => {
      return getUserById(currentUser?.userId as number)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (error) => {
        const err = error as AxiosError

        if (err?.response?.status === 401) {
          logout()
        }
        if (err?.response) {
          const errorText = errorHandler(err?.response?.status, '')
          // setStatus(errorText)
        }
        console.error(err)
      },
    }
  )

  const handleLogOut = () => {
    logout()
  }

  return (
    <div className='app-sidebar-footer flex-column-auto p-4' id='kt_app_sidebar_footer'>
      <div
        className='flex flex-start overflow-hidden text-nowrap sidebar-footer-wrapper'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
      >
        <div className='w-100'>
          <div
            className={'cursor-pointer symbol symbol-35px symbol-md-40px d-flex'}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <div className='symbol symbol-50px me-4'>
              <div className='sidebar-avatar'>
                <KTSVG path='/media/alterGo/icons/user.svg' className='svg-icon-2 m-0 text-white' />
              </div>
            </div>
            <div className='d-flex flex-column justify-content-center flex-grow-1'>
              <div className='d-flex align-items-center fs-6 text-start text-white'>
                {user?.firstName} {user?.lastName}{' '}
                {!user?.firstName && !user?.lastName && user?.emailAddress}
              </div>
              <span className='text-muted fs-7 text-start'>
                {selectUserRole(user?.roleId as number)}
              </span>
            </div>
            <div className='d-flex flex-column justify-content-center'>
              <button className='btn p-1' onClick={handleLogOut}>
                <KTSVG
                  path='/media/alterGo/icons/logout.svg'
                  className='svg-icon-2 m-0 text-white'
                />
              </button>
            </div>
          </div>
          <HeaderUserMenu
            refetchUser={refetchUser}
            user={user as UserDTO}
            isLoading={isLoading}
            error={error}
          />
        </div>
      </div>
    </div>
  )
}

export {SidebarFooter}
