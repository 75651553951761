//import { UserEditModalForm } from './content/UserEditModalForm'
import { UserEditModalForm } from '@/app/modules/users/users-list/user-edit-or-create-modal/content/UserEditModalForm'
import { FC } from 'react'
import { UserDTO } from '../../../../../../app/models/dbModels'

type Props = {
  isLoading: boolean
  user: UserDTO
  error: unknown
  cancel: () => void
}

const UserEditModalFormWrapper: FC<Props> = ({ user, isLoading, error, cancel }) => {


  const selectType = (user: UserDTO, isUserLoading: boolean) => {
    return <UserEditModalForm isUserLoading={isUserLoading} User={user} altCancel={cancel} />
  }



  return (
    <>
      {!user && <div>Betöltés..</div>}
      {!isLoading && !error && user && selectType(user, isLoading)}

    </>

  )
  // if (!itemForUpdate) {
  //   return <div>Loading...</div>
  // }

  // if (!isLoading && !error && user) {
  // return selectType(user,isLoading)
  // }


}

export { UserEditModalFormWrapper }
