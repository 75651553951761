import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialOrderListView,
  UserListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'
import { UserDTO } from '../../../../models/dbModels'

const ListViewContext = createContext<UserListViewContextProps>(initialOrderListView)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialOrderListView.selected)
  const [itemForUpdate, setItemForUpdate] = useState<UserDTO>()
  const [itemForView, setItemForView] = useState<UserDTO>()
  const [orderModal, setOrderModal] = useState<boolean>(false)
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  
  return (
    <ListViewContext.Provider
      value={{
        selected,
        orderModal,
        setOrderModal,
        itemForUpdate,
        setItemForUpdate,
        itemForView,
        setItemForView,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
