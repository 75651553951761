import {FC} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'

type Props = {
  episodeId: number
}

const InventoryObjectEditOrCreateModalHeader: FC<Props> = (episodeId) => {
  const {setItemForUpdate, itemForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>
        {itemForUpdate?.inventoryObjectId ? 'Reward szerkesztése' : 'Reward létrehozása'}
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-inventory-objects-modal-action='close'
        onClick={() => setItemForUpdate?.(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {InventoryObjectEditOrCreateModalHeader}
