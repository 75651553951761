import axios, {AxiosResponse, AxiosError} from 'axios'
import {CreateOrUpdateSeriesRequest, GetSeriesByConditionResponse} from '@/app/models/series'
import {ID} from '../../../../_metronic/helpers'
import {
  GetSeriesByConditionRequest,
  SeriesDTO,
  SeriesStateEnum,
  transformRequestData,
} from '../../../models/dbModels'

const API_SERIES_URL = process.env.REACT_APP_ALTERGO_API + 'Series/'

const appendField = (formData: FormData, key: string, value: any) => {
  if (value !== undefined && value !== null) {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        formData.append(`${key}[${index}]`, item.toString())
      })
    } else {
      formData.append(key, value.toString())
    }
  }
}

const GetSeriesByCondition = (request: GetSeriesByConditionRequest) => {
  const requestQuery = new URLSearchParams({
    seriesId: request.seriesId?.toString() || '',
    userId: request.userId?.toString() || '',
    seriesName: request.seriesName || '',
    needEpisodes: request.needEpisodes?.toString() || 'false',
    needEvents: request.needEvents?.toString() || 'false',
    needUserXSeries: request.needUserXSeries?.toString() || 'false',
    page: request.page?.toString() || '',
    'per-page': request.perPage?.toString() || '',
    sort: request.sort || '',
    order: request.order || '',
    search: request.search || '',
  }).toString()

  return axios
    .get<GetSeriesByConditionResponse>(`${API_SERIES_URL}GetSeriesByCondition?${requestQuery}`)
    .then((response: AxiosResponse<GetSeriesByConditionResponse>) => response.data.data)
}

const CreateOrUpdateSeries = async (series: SeriesDTO): Promise<AxiosResponse<SeriesDTO>> => {
  const url = `${API_SERIES_URL}CreateOrUpdateSeries`

  try {
    const _request: CreateOrUpdateSeriesRequest = {
      SeriesId: series.seriesId,
      SeriesName: series.seriesName || '',
      State: series.state || SeriesStateEnum.Available,
      ColorHex: series.colorHex,
      Episodes: series.episodes,
      UserXSeries: series.userXSeries,
      AssignToAllUsers: series.assignToAllUsers,
    }

    const response = await axios.post<SeriesDTO>(url, _request, {
      headers: {'Content-Type': 'application/json'},
    })
    return response
  } catch (error) {
    const err = error as AxiosError
    if (err.response) {
      console.error('Server response error:', err.response.data)
    } else {
      console.error('Error saving series:', err.message)
    }
    throw error
  }
}

const DeleteSeriesRequest = (seriesId: ID): Promise<void> => {
  return axios.post(`${API_SERIES_URL}DeleteSeries`, {
    seriesId,
  })
}

const AssignUserToSeries = (payload: any): Promise<void> => {
  return axios.post(`${API_SERIES_URL}AssignUserToSeries`, payload)
}

export {GetSeriesByCondition, CreateOrUpdateSeries, DeleteSeriesRequest, AssignUserToSeries}
