import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import { initialSeriesData } from '@/app/models/series'
import { SeriesListFilter } from './SeriesListFilter'

const SeriesListToolbar = () => {
  const {setItemForUpdate} = useListView()
  const openAddItemModal = () => {
    if(setItemForUpdate !== undefined)
      setItemForUpdate(initialSeriesData)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <SeriesListFilter />
      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddItemModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Új sorozat
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {SeriesListToolbar}
