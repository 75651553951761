import { FC } from 'react';
import { ListViewProvider } from './core/ListViewProvider';
import { QueryRequestProvider } from './core/QueryRequestProvider';
import { QueryResponseProvider } from './core/QueryResponseProvider';
import { InventoryObjectList } from './table/InventoryObjectList';


type Props = {
  episodeId: number;
};

const InventoryObjectPageWrapper: FC<Props> = ({ episodeId }) => (
  <QueryRequestProvider>
    <QueryResponseProvider episodeId={episodeId}>
      <ListViewProvider>
        <InventoryObjectList episodeId={episodeId}/>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { InventoryObjectPageWrapper };
