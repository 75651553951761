import {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {DeleteSeriesRequest} from '../../core/_requests'
import Swal from 'sweetalert2'
import {useMutation, useQueryClient} from 'react-query'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {SeriesDTO} from '../../../../models/dbModels'

type Props = {
  data: SeriesDTO
}

const ActionsCell: FC<Props> = ({data}) => {
  const navigate = useNavigate()
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  const openEditPage = () => {
    navigate(`/series/edit/${data.seriesId}`)
  }

  const deleteItem = useMutation(async () => await DeleteSeriesRequest(data.seriesId), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.SERIES}-${query}`])
      refetch()
    },
  })

  const handleDeleteClick = async () => {
    const result = await Swal.fire({
      title: 'Biztos, hogy törölni szeretnéd a csomagot?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Törlés',
      cancelButtonText: 'Mégse',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      await deleteItem.mutateAsync()
    }
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px p-3'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item'>
          <a className='menu-link px-3' onClick={openEditPage}>
            Szerkesztés
          </a>
        </div>
        <div className='menu-item'>
          <a
            className='menu-link color-esl-red px-3'
            data-kt-users-table-filter='delete_row'
            onClick={handleDeleteClick}
          >
            Töröl
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ActionsCell}
