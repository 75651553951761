import {FC, useMemo} from 'react'
import {UserDTO} from '../../../../../models/dbModels'

type Props = {
  user: UserDTO
  selectedUsers: number[]
  onUserSelect: (userId: number) => void
}

const UserSelectionCell: FC<Props> = ({user, selectedUsers = [], onUserSelect}) => {
  const isSelected = useMemo(() => {
    return (
      user &&
      selectedUsers &&
      selectedUsers.some((selectedUserId) => selectedUserId && selectedUserId === user.userId)
    )
  }, [user, selectedUsers])

  const handleChange = () => {
    if (user) {
      onUserSelect(user.userId)
    }
  }

  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        checked={!!isSelected}
        onChange={handleChange}
      />
    </div>
  )
}

export {UserSelectionCell}
