import {Response} from '@/_metronic/helpers'
import {RequestBaseDTO} from './requestBaseDTO'
import {boolean} from 'yup'

//#region ENUMS
export enum SeriesStateEnum {
  Available = 0,
  Completed = 1,
}

export enum InventoryObjectTypeEnum {
  Item = 0,
  Character = 1,
  Loot = 2,
  Clue = 3,
}

export enum EpisodeStateEnum {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2,
}
//#endregion

//#region BASE MODEL
export interface BaseModel {
  isDeleted?: boolean
  creationDate?: Date
  deletedDate?: Date | null
}

export const defaultBaseModel: BaseModel = {
  isDeleted: false,
  creationDate: new Date(),
  deletedDate: null,
}
//#endregion

//#region MAIN TABLES
export type SeriesDTO = BaseModel & {
  seriesId?: number
  seriesName?: string
  state?: SeriesStateEnum
  colorHex: string
  episodes?: Array<EpisodeDTO>
  userXSeries?: Array<UserXSeriesDTO>
  assignToAllUsers?: boolean
}

export type UserDTO = BaseModel & {
  userId: number
  userName: string
  password?: string
  firstName: string
  lastName: string
  genderId: number | string
  emailAddress: string
  phoneNumber: string
  postalCode: number
  dateOfBirth: Date
  aszfAccepted: boolean
  marketingStatementAccepted: boolean
  isAdult: boolean
  roleId: number
  role?: RoleDTO
}

export type RoleDTO = BaseModel & {
  roleId: number
  roleName: string
}

export type CoordinateDTO = BaseModel & {
  coordinateId: number
  latitude: number
  longitude: number
}

export type EventDTO = BaseModel & {
  eventId: number
  eventName: string
  description: string
  imageSource: string
  poiId?: number
  poi?: PoiDTO
  arGameId?: number
  arGame?: ARGameDTO
  survey: Array<TestQuestionDTO>
}

export type PoiDTO = BaseModel & {
  poiId: number
  poiName: string
  coordinateId: number
  coordinate: CoordinateDTO
  radius: number
  poiIcon: string
  imageSource: string
  description: string
  companionMode?: boolean
  companionDelay?: number
}

export type ARGameDTO = BaseModel & {
  arGameId: number
  modelName: string
  numberOfItems?: number
  placementRadius?: number
}

export type InventoryObjectDTO = BaseModel & {
  inventoryObjectId: number
  episodeId: number
  inventoryObjectName: string
  description: string
  imageSource: string
  inventoryObjectType: InventoryObjectTypeEnum
  userCompletedEpisodeXInventoryObject?: Array<UserCompletedEpisodeXInventoryObjectDTO>
}

export type NewsDTO = BaseModel & {
  newsId: number
  newsName: string
  description: string
  imageSource: string
}

export type PreconditionDTO = BaseModel & {
  preconditionId: number
  mandatoryEpisodeMin?: number
  mandatoryFromDate?: Date
  mandatoryEpisodes: Array<EpisodePreconditionDTO>
}

export type EpisodeDTO = BaseModel & {
  episodeId: number
  episodeName: string
  state?: EpisodeStateEnum
  seriesId: number
  eventId?: number
  event?: EventDTO
  extraEventId?: number
  extraEvent?: EventDTO
  isQr: boolean
  episodeRewards: Array<EpisodeRewardDTO>
  preconditions: Array<EpisodePreconditionDTO>
}
//#endregion

//#region JUNCTION TABLES
export type UserXSeriesDTO = BaseModel & {
  userXSeriesId: number
  userId: number
  user: UserDTO
  seriesId: number
}

export type EpisodeXSeriesDTO = BaseModel & {
  episodeXSeriesId: number
  seriesId: number
  episodeId: number
}

export type UserCompletedEpisodeDTO = BaseModel & {
  userCompletedEpisodeId: number
  seriesId: number
  episodeId: number
}

export type EpisodePreconditionDTO = BaseModel & {
  episodePreconditionId: number
  episodeId: number
  preconditionId: number
  precondition: PreconditionDTO
}

export type UserCompletedEpisodeXInventoryObjectDTO = BaseModel & {
  userCompletedEpisodeXInventoryObjectId: number
  userCompletedEpisodeId: number
  inventoryObjectId: number
  inventoryObject: InventoryObjectDTO
}

export type EpisodeRewardDTO = BaseModel & {
  episodeRewardId: number
  episodeId: number
  inventoryObjectId: number
}
//#endregion

//#region TestQuestion and Options
export type TestQuestionDTO = BaseModel & {
  testQuestionId: number
  question: string
  correctOptionId: number
  correctOption: TestQuestionOptionDTO
  options: Array<TestQuestionOptionDTO>
  eventId: number
  event: EventDTO
}

export type TestQuestionOptionDTO = BaseModel & {
  testQuestionOptionId: number
  text: string
  testQuestionId: number
  testQuestion: TestQuestionDTO
}
//#endregion

//#region Request/Response DTOs
export type CreateOrUpdateSeriesRequest = {
  series: SeriesDTO
}

export type CreateOrUpdateSeriesResponse = Response<SeriesDTO>

export type GetSeriesByConditionRequest = RequestBaseDTO & {
  seriesId?: number
  userId?: number
  seriesName?: string
  needEpisodes?: boolean
  needEvents?: boolean
  needUserXSeries?: boolean
}

export type GetSeriesByConditionResponse = Response<Array<SeriesDTO>>

export type DeleteSeriesRequest = {
  seriesId: number
}

export type DeleteSeriesRequestResponse = Response<{
  data: SeriesDTO
  isSuccessful: boolean
}>
//#endregion

//#region Init
export const initialSeriesData: SeriesDTO = {
  seriesId: 0,
  seriesName: '',
  colorHex: '',
  episodes: [],
  userXSeries: [],
  creationDate: new Date(),
  isDeleted: false,
}
//#endregion

//#region EpisodeStateEnum
export type GetEpisodeByConditionRequest = RequestBaseDTO & {
  userId?: number
  episodeId?: number
  seriesId?: number
  eventId?: number
  extraEventId?: number
  isQr?: boolean
  needEpisodeRewards?: boolean
  needPreconditions?: boolean
}

export type GetEpisodeResponse = Response<Array<EpisodeDTO>>

export type GetUserCompletedEpisodeByConditionRequest = RequestBaseDTO & {
  userId?: number
  seriesId?: number
  needSeries?: boolean
  needEpisodeReward?: boolean
}

// GetUserCompletedEpisodeResponse.ts
export type GetUserCompletedEpisodeResponse = Response<Array<UserCompletedEpisodeDTO>>

// GetUserItemsByEpisodeRequest.ts
export type GetUserItemsByEpisodeRequest = {
  userId?: number
  seriesId?: number
  episodeId?: number
  needEpisode?: boolean
  needSeries?: boolean
}

// CreateOrUpdateCompletedEpisodeRequest.ts
export type CreateOrUpdateCompletedEpisodeRequest = {
  userCompletedEpisodeId: number
  userId: number
  seriesId: number
  episodeId: number
  episodeRewardId?: number[]
}

//#endregion

export type CreateOrUpdateEpisodeRequest = {
  episodeId: number
  episodeName: string
  state: EpisodeStateEnum
  seriesId: number
  eventId?: number
  extraEventId?: number
  isQr: boolean
  inventoryObjectIds?: Array<number>
  preconditionIds?: Array<number>
}

export const transformRequestData = <T extends Record<string, any>>(data: T): T => {
  const transformedData: Record<string, any> = {...data}

  Object.keys(transformedData).forEach((key) => {
    const value = transformedData[key]
    if (typeof value === 'number' && value === 0) {
      transformedData[key] = undefined
    } else if (typeof value === 'string' && value === '') {
      transformedData[key] = undefined
    } else if (Array.isArray(value) && value.length === 0) {
      transformedData[key] = undefined
    }
    //   else if (typeof value === 'boolean' && value === false) {
    //     transformedData[key] = undefined;
    //   }
  })

  return transformedData as T
}

//#region InventoryObject
export type CreateOrUpdateInventoryObjectRequest = {
  inventoryObjectId: number
  episodeId: number
  inventoryObjectName: string
  description: string
  imageSource: string
  inventoryObjectType: number
}

export type DeleteInventoryObjectRequest = {
  inventoryObjectId: number
}

export type GetInventoryObjectByConditionRequest = RequestBaseDTO & {
  inventoryObjectId?: number
  episodeId?: number
  inventoryObjectName?: string
}

export type GetInventoryObjectResponse = Response<Array<InventoryObjectDTO>>
//#endregion
