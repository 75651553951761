import {KTSVG} from '@/_metronic/helpers'
import {useModal} from '@/app/components/hooks'

import './index.css'
import {Event} from './Event'
import {Ar} from './AR'
import {POI} from './POI'
import {useEventsByEpisodeId} from './Event/api'
import clsx from 'clsx'
import {Questions} from './Questions/index'

export const Events = ({episodeId}: {episodeId: number}) => {
  const {modal, setModal} = useModal()
  const {event, ar, questions, poi, refetch} = useEventsByEpisodeId(episodeId)

  const boxProps = {episodeId, modal, setModal, refetch, eventId: event?.id}

  return (
    <>
      <div>
        <h1>Események</h1>
        <div className='d-flex justify-content-end align-items-center mb-5'>
          {/* Event is created by default */}
          {/* {!event && (
            <div className='btn btn-primary me-3' onClick={() => setModal('createOrUpdateEvent')}>
              {Plus} Új Event
            </div>
          )} */}

          {!ar && (
            <div
              className={clsx('btn btn-primary me-3', {disabled: !!questions})}
              onClick={() => setModal('createAr')}
            >
              {Plus} Új AR
            </div>
          )}
          <div
            className={clsx('btn btn-primary me-3', {disabled: !!ar})}
            onClick={() => setModal('createQuestion')}
          >
            {Plus} Új Kérdés
          </div>

          <div
            className={clsx('btn me-3', !!poi ? 'btn-success' : 'btn-primary', {disabled: !!poi})}
            onClick={() => setModal('createPOI')}
          >
            {!!poi ? Check : Plus} POI {!!poi ? 'beállítva' : 'beállítása'}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-md-6 p-10'>
          {/* Event doboz */}
          <Event {...boxProps} event={event} />

          {/* AR doboz */}
          <Ar {...boxProps} ar={ar} />
        </div>

        {/* POI doboz */}
        <POI {...boxProps} poi={poi} />
      </div>

      {/* Kérdések tábla */}
      <Questions {...boxProps} questions={questions} />
    </>
  )
}

const Plus = <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
const Check = <KTSVG path='/media/icons/duotune/arrows/arr016.svg' className='svg-icon-2' />
