export const dropdownMenuConfig = {
  edit: {
    name: 'Szerkesztés',
    onClick() {
      alert(1)
    },
  },
  remove: {
    name: 'Törlés',
    className: 'color-esl-red',
    onClick() {
      alert(1)
    },
  },
}
