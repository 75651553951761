import {apiURL} from '@/app/consts'
import axios from 'axios'

const baseURL = apiURL + 'Precondition'

interface PaginateAndSearchParams {
  page?: number
  perPage?: number
  sort?: string
  order?: string
  search?: string
}

interface GetPreconditionsByEpisodeIdParams {
  episodeId: number
}

export const getPreconditionsByEpisodeId = async (
  params: GetPreconditionsByEpisodeIdParams & PaginateAndSearchParams
) => {
  let result
  try {
    const response = await axios.get(`${baseURL}/GetPreconditionsByEpisode`, {
      params: {
        ...params,
        'per-page': params.perPage,
      },
    })
    result = response.data
  } catch (error) {
    console.error(error)
  }
  return result
}

export interface CreateOrUpdatePreconditionParams {
  preconditionId: number
  episodeId: number
  mandatoryDate: string
  mandatoryEpisodeMin: number
  mandatoryEpisodeIds: string
}

export const createOrUpdatePrecondition = async (
  params: CreateOrUpdatePreconditionParams
): Promise<any> => {
  try {
    const response = await axios.post(`${baseURL}/CreateOrUpdatePrecondition`, params)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
