import {useMutation, useQuery} from 'react-query'
import {createOrUpdatePrecondition, getPreconditionsByEpisodeId} from './api'
import {Modal} from '@/app/components/Modal'
import {EpisodePreconditionDTO} from '@/app/models/dbModels'
import './index.css'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {useModal} from '@/app/components/hooks'
import {Field} from '@/app/components/CustomInput'
import {formatDate} from 'date-fns'

const preconditionsSchema = Yup.object().shape({
  mandatoryDate: Yup.string().nullable(),
  mandatoryEpisodeMin: Yup.number().required('Kötelező mező'),
  mandatoryEpisodeIds: Yup.string().when('mandatoryEpisodeMin', {
    is: (value: any) => value && value > 0,
    then: Yup.string().required('Ez a mező kötelező.'),
    otherwise: Yup.string(),
  }),
})

export const Preconditions = ({episodeId}: {episodeId: number}) => {
  const {modal, setModal} = useModal()
  const {data: preconditionsResponse, refetch} = useQuery({
    queryKey: ['preconditions', episodeId],
    queryFn: async () => getPreconditionsByEpisodeId({episodeId}),
  })
  const setPrecondition = useMutation({
    mutationFn: createOrUpdatePrecondition,
  })

  const preconditions = preconditionsResponse?.data

  const preconditionToEdit = {
    mandatoryDate: preconditions?.mandatoryDate ? new Date(preconditions?.mandatoryDate) : null,
    mandatoryEpisodeMin: preconditions?.mandatoryEpisodeMin || '',
    mandatoryEpisodeIds: (preconditions?.mandatoryEpisodeIds || []).join(','),
  }

  const preconditionsToSend: any = {
    episodeId,
  }
  if (preconditions) {
    preconditionsToSend.preconditionId = preconditions?.preconditionId
  }

  const onFormSubmit = async (values: any) => {
    const mutation = {
      ...preconditionsToSend,
      mandatoryDate: values.mandatoryDate,
      mandatoryEpisodeMin: parseInt(values.mandatoryEpisodeMin),
      mandatoryEpisodeIds: values.mandatoryEpisodeIds,
    }
    const res = await setPrecondition.mutate(mutation)
    await new Promise((res) => setTimeout(res, 500))
    await refetch()
    setModal('')
  }

  return (
    <div>
      <h1>Előfeltételek</h1>

      <div className='d-flex justify-content-end align-items-center mb-5'>
        <div className='btn btn-primary' onClick={() => setModal('edit')}>
          Szerkesztés
        </div>
      </div>

      <table className='preconditions-table table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
        <thead>
          <tr>
            <td>Dátum</td>
            <td>Min. epizód</td>
            <td>Epizód id-k</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {preconditions?.mandatoryDate
                ? formatDate(preconditions?.mandatoryDate, 'yyyy.MM.dd.')
                : '-'}
            </td>
            <td>{preconditions?.mandatoryEpisodeMin}</td>
            <td>
              {preconditions?.mandatoryEpisodeIds
                ?.map((episode: EpisodePreconditionDTO) => episode)
                .join(',')}
            </td>
          </tr>
        </tbody>
      </table>

      <Modal open={!!modal} setModal={setModal}>
        {preconditionToEdit && (
          <Formik
            enableReinitialize
            initialValues={preconditionToEdit}
            validationSchema={preconditionsSchema}
            onSubmit={onFormSubmit}
          >
            <Form>
              <Field name='mandatoryDate' datepicker label={'Ekkortól elérhető'} />
              <Field name='mandatoryEpisodeMin' label={'Minimum epizódszám'} />
              <Field name='mandatoryEpisodeIds' label={'Epizód id-k'} />

              <div className='d-flex justify-content-center align-items-center mt-10'>
                <button type='submit' className='btn btn-primary'>
                  MÓDOSÍTÁS
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </Modal>
    </div>
  )
}
