import {Response} from '../../_metronic/helpers'
import {UserDTO} from './dbModels'

export type UsersQueryResponse = Response<Array<UserDTO>>
export type UserQueryResponse = Response<UserDTO>

//#endregion Enum
export enum UserRoleEnum {
  ADMIN = 1,
  USER = 2,
}

export enum UserGenderEnum {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}

export const userRoleList = [
  {id: UserRoleEnum.ADMIN, name: 'Adminisztrátor'},
  {id: UserRoleEnum.USER, name: 'Felhasználó'},
]

export enum RightEnum {
  _admin = 1,
  _user = 1,
}
//#endregion

export const initialUser: UserModel = {
  userName: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  postalCode: 0,
  marketingStatementAccepted: false,
  isAdult: false,
  // roleId: '',
  password: '',
  // _confirmPassword: '',
  aszfAccepted: false,
  dateOfBirth: new Date(),
  userId: 0,
  genderId: '',
  roleId: UserRoleEnum.ADMIN,
  role: undefined,
  creationDate: new Date(),
  isDeleted: false,
  _confirmPassword: '',
}

export type UserModel = UserDTO & {
  _confirmPassword: string
}

export type UserAuthModel = UserDTO & {
  _temporaryPasswordTokens: string
}
